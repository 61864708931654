import { UserPermission } from '../../reducers/permissions/types';
import {
    UPDATE_USER_PERMISSIONS,
    UpdateUserPermissionsAction,
} from './types';

export function updatePermissions(userPermissions: Array<UserPermission>): UpdateUserPermissionsAction {
    return {
        type: UPDATE_USER_PERMISSIONS,
        payload: userPermissions,
    };
}
