import { LocationChangeAction } from 'connected-react-router';
import { UPDATE_WAREHOUSES, WarehouseActions } from '../../actions/warehouseEmployee/warehouseSelection/types';
import { WarehousesState } from './types';

export const initialState: WarehousesState = {
    availableWarehouses: [],
};

export default function warehousesReducer(
    state: WarehousesState = initialState,
    action: WarehouseActions | LocationChangeAction,
): WarehousesState {
    switch (action.type) {
        case UPDATE_WAREHOUSES:
            return {
                ...state,
                availableWarehouses: action.payload,
            };
        default:
            return state;
    }
}
