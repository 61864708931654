import omit from 'lodash/fp/omit';
import { config } from '../../config';

const stripSnakeProps = omit(['family_name', 'given_name', 'preferred_username']);

// TODO: Depending on your client subscriptions you may want to map
//       additional properties from the OAuth profile
export const mapUserProfile = (profile = {}) => ({
    azp: profile.azp,
    email: profile.email,
    familyName: profile.family_name,
    givenName: profile.given_name,
    preferredUsername: profile.preferred_username,
    locale: profile.locale,
    name: profile.name,
    sub: profile.sub,
    account: profile.account ? profile.account : config.defaultAccount,
    ...stripSnakeProps(profile),
});
