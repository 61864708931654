import { ColumnSetting } from '../../reducers/ui/types';
import { Role } from '../../types';

export const SET_DEFAULT_FOR_ROLE_TYPE = 'TABLE_SETTINGS/SET_DEFAULT_FOR_ROLE' as const;
export const SET_COLUMN_SETTINGS_TYPE = 'TABLE_SETTINGS/SET_COLUMN_SETTINGS' as const;

export const setDefaultForRole = (role: Role) => ({
    type: SET_DEFAULT_FOR_ROLE_TYPE,
    payload: role,
});

export const setColumnSettings = (settings: ColumnSetting[]) => ({
    type: SET_COLUMN_SETTINGS_TYPE,
    payload: settings,
});

export type SetDefaultsForRoleAction = ReturnType<typeof setDefaultForRole>;
export type SetColumnSettingsAction = ReturnType<typeof setColumnSettings>;
