import { connect } from 'react-redux';
import { State } from '../../../../types';
import {
    isAuditorViewAccessible,
    isCarrierViewAccessible,
    isWarehouseEmployeeViewAccessible,
} from '../../selectors/Permissions.selectors';
import { Routes } from './Routes';
import { RoutesProperties } from './types';

function mapStateToProps(state: State): RoutesProperties {
    return {
        canAccessAuditorView: isAuditorViewAccessible(state),
        canAccessCarrierView: isCarrierViewAccessible(state),
        canAccessWarehouseEmployeeView: isWarehouseEmployeeViewAccessible(state),
    };
}

export const RoutesContainer = connect(mapStateToProps)(Routes);
