export interface WarehouseEmployeeTransportOrderEditSidebarState {
    isLoadingProgressFormDirty: boolean;
}

const initialState = {
    isLoadingProgressFormDirty: false,
};

export const ORDER_EDIT_SIDEBAR_LOADING_PROGRESS_FORM_SET_DIRTY = 'orderEditSidebar/TOGGLE_LOADING_PROGRESS_FORM_DIRTY';

export interface ToggleDirtyForWarehouseEmployeeLoadingProgressForm {
    payload: { dirty: boolean };
    type: typeof ORDER_EDIT_SIDEBAR_LOADING_PROGRESS_FORM_SET_DIRTY;
}

export type WarehouseEmployeeTransportOrderEditSidebarActions =
    ToggleDirtyForWarehouseEmployeeLoadingProgressForm;

export default function warehouseEmployeeTransportOrderEditSidebarReducer(
    state: WarehouseEmployeeTransportOrderEditSidebarState = initialState,
    action: WarehouseEmployeeTransportOrderEditSidebarActions,
): WarehouseEmployeeTransportOrderEditSidebarState {
    switch (action.type) {
        case ORDER_EDIT_SIDEBAR_LOADING_PROGRESS_FORM_SET_DIRTY:
            return {
                isLoadingProgressFormDirty: action.payload.dirty,
            };
        default:
            return state;
    }
}
