import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getAccessToken, getIdToken, getLanguageData, getLocale, isUserSessionExpired } from '../../../configuration';
import { Dispatch, State } from '../../../types';
import {
    completeInitialization,
    hideSessionExpiredDialog,
    initialActions,
    setInitializationFailed,
} from '../actions/App.actions';
import { getInitializationState, getSessionExpiredAcknowledged } from '../selectors/App.selector';
import App from './App';
import { AppPropertiesFromDispatch, AppPropertiesFromState } from './types';

function mapDispatchToProps(dispatch: Dispatch): AppPropertiesFromDispatch {
    return {
        hideSessionDialog: () => dispatch(hideSessionExpiredDialog()),
        initialActions: () => dispatch(initialActions()),
        completeInitialization: () => dispatch(completeInitialization()),
        setInitializationFailed: () => dispatch(setInitializationFailed()),
    };
}

function mapStateToProps(state: State): AppPropertiesFromState {
    return {
        accessToken: getAccessToken(state) as string,
        idToken: getIdToken(state),
        languageData: getLanguageData(state),
        showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
        userLocale: getLocale(state),
        initializationState: getInitializationState(state),
    };
}

export const AppContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
