import { connect } from 'react-redux';
import { config } from '../../../../config';
import { getUserProfile } from '../../../../configuration/login/selectors';
import { State } from '../../../../types';
import { VolkswagenAccountMenuProps } from './types';
import { VolkswagenAccountMenu } from './VolkswagenAccountMenu';

function mapStateToProps(state: State): VolkswagenAccountMenuProps {
    const idToken = getUserProfile(state as any) as any;
    return {
        firstName: idToken?.givenName,
        lastName: idToken?.familyName,
        email: idToken?.email,
        preferredUsername: idToken?.preferredUsername,
        logout: () => {
            if (config.logoutUri) {
                window.location.href = config.logoutUri;
            }
        },
    };
}

export const VolkswagenAccountMenuContainer = connect(mapStateToProps)(VolkswagenAccountMenu);
