import { UserPermission } from '../../reducers/permissions/types';

export const UPDATE_USER_PERMISSIONS = 'userPermissions/UPDATE_USER_PERMISSIONS';

export interface UpdateUserPermissionsAction {
    type: typeof UPDATE_USER_PERMISSIONS;
    payload: Array<UserPermission>;
}

export type PermissionsActions =
    UpdateUserPermissionsAction;
