export interface PermissionsState {
    userPermissions: Array<UserPermission>;
}

export enum UserPermissionValue {
    TRANSPORT_ORDER_READ = 'TRANSPORT_ORDER_READ',
    TRANSPORT_ORDER_CREATE = 'TRANSPORT_ORDER_CREATE',
    TRANSPORT_ORDER_EDIT = 'TRANSPORT_ORDER_EDIT',
    TRANSPORT_ORDER_PLAN = 'TRANSPORT_ORDER_PLAN',
    TRANSPORT_ORDER_LOAD = 'TRANSPORT_ORDER_LOAD',
    TRANSPORT_ORDER_AUDIT = 'TRANSPORT_ORDER_AUDIT',
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface UserPermission {
    permissionValue: UserPermissionValue;
    warehouseIds: Array<number>;
}

export const USER_PERMISSION_VALUES: UserPermissionValue[] = Object.values(UserPermissionValue);
