import _ from 'lodash';
import { State } from '../../../types';
import { UserPermissionValue } from '../reducers/permissions/types';
import { Warehouses } from '../reducers/warehouses/types';
import { getSelectedWarehouseId } from './Router.selector';

const WAREHOUSE_EMPLOYEE_USER_PERMISSION_VALUES = [
    UserPermissionValue.TRANSPORT_ORDER_READ,
    UserPermissionValue.TRANSPORT_ORDER_EDIT,
    UserPermissionValue.TRANSPORT_ORDER_LOAD,
];

const CARRIER_USER_PERMISSION_VALUES = [
    UserPermissionValue.TRANSPORT_ORDER_READ,
    UserPermissionValue.TRANSPORT_ORDER_PLAN,
];

const AUDITOR_USER_PERMISSION_VALUES = [
    UserPermissionValue.TRANSPORT_ORDER_READ,
    UserPermissionValue.TRANSPORT_ORDER_AUDIT,
];

export function getAllowedWarehouseIds(a: State): Array<number> {
    return _.intersection(...(WAREHOUSE_EMPLOYEE_USER_PERMISSION_VALUES.map(permissionValue => {
        const warehouseEmployeePermission = a.otc.permissions.userPermissions
            .find(userPermission => userPermission.permissionValue === permissionValue);
        return warehouseEmployeePermission ? warehouseEmployeePermission.warehouseIds : [];
    })));
}

export function getAllowedWarehouses(a: State): Warehouses {
    return a.otc.warehouses.availableWarehouses
        .filter(warehouse => getAllowedWarehouseIds(a).includes(warehouse.id));
}

export function isWarehouseEmployeeViewAccessible(a: State): boolean {
    return _.difference(
        WAREHOUSE_EMPLOYEE_USER_PERMISSION_VALUES,
        a.otc.permissions.userPermissions.map(userPermission => userPermission.permissionValue),
    ).length === 0;
}

export function isCarrierViewAccessible(a: State): boolean {
    return _.difference(
        CARRIER_USER_PERMISSION_VALUES,
        a.otc.permissions.userPermissions.map(userPermission => userPermission.permissionValue),
    ).length === 0;
}

export function isAuditorViewAccessible(a: State): boolean {
    return _.difference(
        AUDITOR_USER_PERMISSION_VALUES,
        a.otc.permissions.userPermissions.map(userPermission => userPermission.permissionValue),
    ).length === 0;
}

export function hasCreateTransportOrdersPermissionForSelectedWarehouse(a: State) {
    const createPermission = a.otc.permissions.userPermissions
        .find(userPermission => userPermission.permissionValue === UserPermissionValue.TRANSPORT_ORDER_CREATE);
    const selectedWarehouseId = getSelectedWarehouseId(a);
    return createPermission !== undefined && selectedWarehouseId !== undefined &&
        createPermission.warehouseIds.includes(selectedWarehouseId);
}
