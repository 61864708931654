import './polyfills';
import { ConnectedRouter } from 'connected-react-router';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { config } from './config';

import { handleLoginRedirect, history, main, store } from './configuration';
import { AppContainer } from './features/app/components/App.container';
import { ErrorBoundary } from './features/app/components/ErrorBoundary';

function renderApplication() {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    // Note that we need to use the base "Router" with a "hash" history
    // because the "HashRouter" doesn't allow handing in a history
    // from the outside. So this is effectively a "HashRouter" despite
    // that not being obvious here
    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <AppContainer />
                </ConnectedRouter>
            </Provider>
        </ErrorBoundary>,
    );
}

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else if (import.meta.env.PROD) {
    main(renderApplication);
} else if (import.meta.env.DEV) {
    import('../mocks/serviceMock').then(({ startWorker }) => startWorker()).then(() => main(renderApplication));
}
