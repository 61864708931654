import {
    TOGGLE_ORDER_CREATION_SIDEBAR_OPEN,
    WarehouseEmployeeTransportOrderCreationSidebarActions,
} from '../../actions/warehouseEmployee/createSidebar/types';
import { WarehouseEmployeeTransportOrderCreationSidebarState } from './types';

const initialState = { isOpen: false };

export default function transportOrdersReducer(
    state: WarehouseEmployeeTransportOrderCreationSidebarState = initialState,
    action: WarehouseEmployeeTransportOrderCreationSidebarActions,
): WarehouseEmployeeTransportOrderCreationSidebarState {
    switch (action.type) {
        case TOGGLE_ORDER_CREATION_SIDEBAR_OPEN:
            return {
                ...state,
                isOpen: !state.isOpen,
            };
        default:
            return state;
    }
}
