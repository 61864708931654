import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { AccessTokenState, LanguageState, LoginState } from './configuration';
import loginReducer from './configuration/login/reducer';
import langReducer from './configuration/lang/reducer';
import configReducer from './configuration/setup/configReducer';
import tokenHandlingReducer from './configuration/tokenHandling/reducer'; // TODO make import from ./configuration work
import appReducer from './features/app/reducers/App.reducer';
import permissionsReducer from './features/app/reducers/permissions/Permissions.reducer';
import { tableSettingsReducer } from './features/app/reducers/ui/tableSettings.reducer';
import warehouseEmployeeTransportOrderEditSidebarReducer
    from './features/app/reducers/ui/WarehouseEmployeeTransportOrderEditSidebar.reducer';
import warehouseEmployeeTransportOrderCreationSidebarReducer
    from './features/app/reducers/warehouseEmployeeTransportOrderCreationSidebar/WarehouseEmployeeTransportOrderCreationSidebar.reducer';
import transportOrdersReducer from './features/app/reducers/transportOrders/TransportOrders.reducer';
import warehousesReducer from './features/app/reducers/warehouses/Warehouses.reducer';
import { UiState, OtcState, State } from './types';

const otcReducer = combineReducers<OtcState>({
    warehouses: warehousesReducer,
    transportOrders: transportOrdersReducer,
    orderCreationSidebar: warehouseEmployeeTransportOrderCreationSidebarReducer,
    permissions: permissionsReducer,
});

const uiReducer = combineReducers<UiState>({
    tableSettings: tableSettingsReducer,
    orderEditSidebar: warehouseEmployeeTransportOrderEditSidebarReducer,
});

export const rootReducer = (history: History) =>
    combineReducers<State>({
        app: appReducer,
        config: configReducer,
        form: formReducer,
        lang: langReducer as Reducer<LanguageState | undefined>,
        login: loginReducer as Reducer<LoginState | undefined>,
        otc: otcReducer,
        router: connectRouter(history),
        tokenHandling: tokenHandlingReducer as Reducer<AccessTokenState | undefined>,
        ui: uiReducer,
    });
