export interface AppState {
    sessionExpiredAcknowledged: boolean;
    initializationState: InitializationState;
}

export enum InitializationState {
    INCOMPLETE = 'INCOMPLETE',
    COMPLETE = 'COMPLETE',
    FAILED = 'FAILED',
}
