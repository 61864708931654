import { AccessToken } from '../../../configuration';
import { ApiRole } from '../../app/actions/types';
import fetchJSON, { fetchResponse, getFetchOptions, getFetchOptionsXLSX } from '../fetchJSON';

export const authenticatedPost = (url: string, body: {}, token: AccessToken, role: ApiRole) => {
    const options = getFetchOptions(role, token, 'POST') as RequestInit;
    options.body = JSON.stringify(body);

    return fetchResponse(url, options);
};

export const authenticatedPatch = (url: string, body: {}, token: AccessToken, role: ApiRole) => {
    const options = getFetchOptions(role, token, 'PATCH') as RequestInit;
    options.body = JSON.stringify(body);

    return fetchResponse(url, options);
};

export const authenticatedGet = (url: string, token: AccessToken, role?: ApiRole) => {
    const options = getFetchOptions(role, token, 'GET') as RequestInit;

    return fetchJSON(url, options);
};

export const authenticatedGetXLSX = (url: string, token: AccessToken, role: ApiRole): Promise<Response> => {
    const options = getFetchOptionsXLSX(role, token) as RequestInit;

    return fetchResponse(url, options);
};

export const authenticatedPut = (url: string, body: {}, token: AccessToken, role: ApiRole) => {
    const options = getFetchOptions(role, token, 'PUT') as RequestInit;

    options.body = JSON.stringify(body);

    return fetchResponse(url, options);
};
