/* eslint-disable no-undef */
import * as Sentry from '@sentry/browser';
import { config } from '../../config';
// version and environment are defined in the webpack.define plugin
const release = config.serviceVersion;
const environment = config.serviceEnvironment;
// should have been called before using it here
// ideally before even rendering your react app
if (import.meta.env.PROD) {
    Sentry.init({
        dsn: config.sentryToken,
        environment,
        release,
    });
}
export const reportErrorToSentry = (...args) => {
    if (import.meta.env.PROD) {
        Sentry.captureException(...args);
    }
};
