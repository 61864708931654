import { State } from '../../../types';
import { Warehouse, Warehouses } from '../reducers/warehouses/types';
import { getSelectedWarehouseId } from './Router.selector';

export function getWarehouses(a: State): Warehouses {
    return a.otc.warehouses.availableWarehouses;
}

export function getSelectedWarehouse(a: State): Warehouse | undefined {
    return getWarehouses(a).find(warehouse => warehouse.id === getSelectedWarehouseId(a));
}

export function getWarehouseById(a: State, id: number): Warehouse | undefined {
    return getWarehouses(a).filter(item => item.id === id)[0];
}
