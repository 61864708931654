import { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Routing } from './Routing';
import { RoutesProperties } from './types';
import { suspendPageLoad } from './suspendPageLoad';

const WarehouseEmployeeTransportOrderCreationSidebarContainer = suspendPageLoad(() => import('../views/warehouseEmployee/transportOrders/sidebars/createSidebar/WarehouseEmployeeTransportOrderCreationSidebar.container'));
const CarrierTransportOrderEditSidebarContainer = suspendPageLoad(() => import('../views/carrier/sidebars/transportOrderEditSidebar/CarrierTransportOrderEditSidebar.container'));
const WarehouseEmployeeTransportOrderEditSidebarContainer = suspendPageLoad(() => import('../views/warehouseEmployee/transportOrders/sidebars/editSidebar/WarehouseEmployeeTransportOrderEditSidebar.container'));
const AuditorTransportOrderDetailsSidebarContainer = suspendPageLoad(() => import('../views/auditor/sidebars/AuditorTransportorderDetailsSidebar.container'));
const WarehouseSelectionViewContainer = suspendPageLoad(() => import('../views/warehouseEmployee/warehouseSelection/WarehouseSelectionView.container'));
const WarehouseEmployeeViewContainer = suspendPageLoad(() => import('../views/warehouseEmployee/transportOrders/WarehouseEmployeeView.container'));
const CarrierViewContainer = suspendPageLoad(() => import('../views/carrier/CarrierView.container'));
const AuditorViewContainer = suspendPageLoad(() => import('../views/auditor/AuditorView.container'));

export const SidebarsLeft = () => {
    return (
        <Switch>
            <Route path={Routing.transportOrders} component={WarehouseEmployeeTransportOrderCreationSidebarContainer} />
        </Switch>
    );
};

export const SidebarsRight = () => {
    return (
        <Switch>
            <Route path={Routing.carrierView} component={CarrierTransportOrderEditSidebarContainer} />
            <Route path={Routing.transportOrders} component={WarehouseEmployeeTransportOrderEditSidebarContainer} />
            <Route path={Routing.auditorView} component={AuditorTransportOrderDetailsSidebarContainer} />
        </Switch>
    );
};

export const Routes: FunctionComponent<RoutesProperties> = (props: RoutesProperties) => {
    return (
        <Switch>
            <Route path={Routing.warehouseSelection} component={WarehouseSelectionViewContainer} />
            <Route path={Routing.transportOrders} component={WarehouseEmployeeViewContainer} />
            <Route path={Routing.carrierView} component={CarrierViewContainer} />
            <Route path={Routing.auditorView} component={AuditorViewContainer} />
            <Redirect from={'/'} to={defaultRoute(props)} />
        </Switch>
    );
};

export const defaultRoute = (props: RoutesProperties): any => {
    const {
        canAccessAuditorView,
        canAccessCarrierView,
    } = props;
    if (canAccessAuditorView) {
        return Routing.auditorView;
    } else if (canAccessCarrierView) {
        return Routing.carrierView;
    }
    return Routing.warehouseSelection;
};
