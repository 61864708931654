import getOr from 'lodash/fp/getOr';

import { reportErrorToSentry } from '../setup/sentry';
import { trace } from '../setup/trace';

import { changeLocale, languageDataFetched } from './actions';
import { DEFAULT_LOCALE, getSupportedLocale as defaultGetSupportedLocale } from './lang';

const MODULE_NAME = `otc-avisierung`;

const sendError = exception => {
    reportErrorToSentry(exception, {
        tags: {
            module: MODULE_NAME,
        },
    });
};

// Webpack is weird sometimes, maybe it's Babel, who knows...
const normalizeDynamicImport = imported => getOr(imported, 'default', imported);

export const getLanguageData = locale =>
    import(/* webpackChunkName: "[request]" */ `../../features/translations/${locale}.json`)
        .then(normalizeDynamicImport)
        .catch(error => {
            sendError(error);
            return error;
        });

export const configureFetchLanguageData = (
    store,
    fetchLanguageData = getLanguageData,
    getSupportedLocale = defaultGetSupportedLocale,
) => locale => {
    if (!locale) {
        console.warn(`No "locale" supplied when fetching language data!`);
        return Promise.reject();
    }

    const supportedLocale = getSupportedLocale(locale);
    return fetchLanguageData(supportedLocale)
        .then(languageData => {
            trace(`Language data fetched for "${supportedLocale}"`);
            store.dispatch(languageDataFetched(supportedLocale, languageData));
        })
        .catch(error => {
            console.error(`Language data for "${supportedLocale}" could not be fetched.`, error);
            sendError(error);
            store.dispatch(changeLocale(DEFAULT_LOCALE));
        });
};
