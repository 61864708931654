import { Warehouses } from '../../../reducers/warehouses/types';

export const UPDATE_WAREHOUSES = 'warehouses/UPDATE_WAREHOUSES';
export const SELECT_WAREHOUSE = 'warehouses/SELECT_WAREHOUSE';

export interface UpdateWarehousesAction {
    type: typeof UPDATE_WAREHOUSES;
    payload: Warehouses;
}

export type WarehouseActions = UpdateWarehousesAction;
