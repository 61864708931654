import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { getAccessToken } from '../../../configuration';
import { Dispatch, State } from '../../../types';
import { authenticatedGet } from '../../api/authenticatedFetches/authenticatedFetches';
import {
    mapPermissionsResponseToUserPermissions,
} from '../mapper/api/permissionsFromExternal';
import { mapWarehouseResponseToWarehouses } from '../mapper/api/warehouseFromExternal';
import { updatePermissions } from './permissions/Permissions.action';
import {
    HIDE_SESSION_EXPIRED_DIALOG,
    HideSessionExpiredAction,
    INITIALIZATION_COMPLETE, INITIALIZATION_FAILED,
    InitializationCompleteAction, InitializationFailedAction,
} from './types';
import { updateWarehouses } from './warehouseEmployee/warehouseSelection/WarehouseSelection.actions';

export function hideSessionExpiredDialog(): HideSessionExpiredAction {
    return {
        type: HIDE_SESSION_EXPIRED_DIALOG,
    };
}

export function completeInitialization(): InitializationCompleteAction {
    return {
        type: INITIALIZATION_COMPLETE,
    };
}

export function setInitializationFailed(): InitializationFailedAction {
    return {
        type: INITIALIZATION_FAILED,
    };
}

export const fetchWarehouses = () =>
    (dispatch: Dispatch, getStore: () => State): Promise<void> => {
        const path = '/warehouses';
        const uri =
            `${import.meta.env.VITE_OTC_TRANSPORTORDER_SERVICE}${path}`;
        const token = getAccessToken(getStore());
        return authenticatedGet(uri, token)
            .then((warehouseResponse: any) => {
                const warehouses = mapWarehouseResponseToWarehouses(warehouseResponse);
                dispatch(updateWarehouses(warehouses));
            });
    };

export const fetchPermissions = () =>
    (dispatch: Dispatch, getStore: () => State): Promise<void> => {
        const path = '/permissions';
        const uri = `${import.meta.env.VITE_OTC_TRANSPORTORDER_SERVICE}${path}`;
        const token = getAccessToken(getStore());
        return authenticatedGet(uri, token)
            .then((permissionsResponse: any) => {
                const userPermissions = mapPermissionsResponseToUserPermissions(permissionsResponse);
                dispatch(updatePermissions(userPermissions));
            });
    };

export const initialActions = () => (dispatch: Dispatch): Promise<any> => {

    return Promise.all([dispatch(fetchWarehouses()), dispatch(fetchPermissions())])
        .catch((err: Error) => {
            Notification.error(err.message);
            throw err;
        });
};
