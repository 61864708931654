export const HIDE_SESSION_EXPIRED_DIALOG = 'app/HIDE_SESSION_EXPIRED_DIALOG';
export const FETCH_WAREHOUSES = 'app/FETCH_WAREHOUSES';
export const INITIALIZATION_COMPLETE = 'app/INITIALIZATION_COMPLETE';
export const INITIALIZATION_FAILED = 'app/INITIALIZATION_FAILED';

export interface HideSessionExpiredAction {
    type: typeof HIDE_SESSION_EXPIRED_DIALOG;
}

export interface FetchWarehousesAction {
    type: typeof FETCH_WAREHOUSES;
}

export interface InitializationCompleteAction {
    type: typeof INITIALIZATION_COMPLETE;
}

export interface InitializationFailedAction {
    type: typeof INITIALIZATION_FAILED;
}

export type AppActions =
    HideSessionExpiredAction
    | FetchWarehousesAction
    | InitializationCompleteAction
    | InitializationFailedAction;

export enum ApiRole {
    CARRIER = 'CARRIER',
    WAREHOUSE_EMPLOYEE = 'WAREHOUSE_EMPLOYEE',
    AUDITOR = 'AUDITOR',
}
