/* eslint-disable camelcase, @typescript-eslint/naming-convention */
import getOr from 'lodash/fp/getOr';
import join from 'lodash/fp/join';
import { InMemoryWebStorage, UserManager, WebStorageStateStore } from 'oidc-client/lib/oidc-client';
import { config, getCurrentTenant, Tenant } from '../../config';
import { reportErrorToSentry } from '../setup/sentry';
import { mapUserProfile } from './userProfile';
import { jwtDecode } from 'jwt-decode';
import { DEFAULT_LOCALE } from '../index';

const trace = !import.meta.env.PROD ? (...args) => console.log(`[oidcLogin]`, ...args) : () => {};

const pullLocale = getOr(DEFAULT_LOCALE, 'profile.locale');

const mapIdToken = (token) => ({
    ...token,
    account: token.account ? token.account : config.defaultAccount,
});

export const adaptPublishedInfo = (result = {}) => ({
    accessToken: result.access_token,
    expiresInSeconds: result.expires_in,
    idToken: mapIdToken(result.profile),
    locale: pullLocale(result),
    profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
    const redirectUri = config.login.redirectUri;
    const silentRedirectUri = config.login.silentRedirectUri;

    const settings = {
        authority: `${config.login.authority}`,
        client_id: `${config.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: `${redirectUri}`,
        scope: join(' ', config.login.oauthScope),
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
        ...getCurrentTenant(window.location.hostname) === Tenant.RIO ? {
            response_type: `code`,
            monitorSession: true,
            post_logout_redirect_uri: `${config.logoutUri}`,
            includeIdTokenInSilentRenew: false,
            automaticSilentRenew: true,
            staleStateAge: 600,
        } : {
            response_type: `id_token token`,
            userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
        },
    };

    trace('oidc.auth.settings', settings);

    return new UserManager(settings);
};

export const configureUserManager = (oauthConfig, userManager) => {
    userManager.events.addUserLoaded(user => {
        trace('oidc.signinSilent success!');
        oauthConfig.onTokenRenewed(adaptPublishedInfo(user));
    });

    userManager.events.addUserUnloaded(() => {
        oauthConfig.onTokenExpired();
    });

    userManager.events.addAccessTokenExpiring((...args) => {
        trace('oidc.accessTokenExpiring', ...args);
        trace('  triggering manual silent renewal...');
        userManager.signinSilent();
    });

    userManager.events.addAccessTokenExpired((...args) => {
        trace('oidc.accessTokenExpired', ...args);
        oauthConfig.onTokenExpired();
    });

    userManager.events.addSilentRenewError(error => {
        trace('oidc.silentRenewError', error);
        reportErrorToSentry(error);

        oauthConfig.onTokenExpired();
    });

    userManager.events.addUserSignedOut((...args) => {
        trace('oidc.userSignedOut', ...args);
        oauthConfig.onTokenExpired();
    });

    return userManager;
};

export const configureMockUserManager = ({ onTokenRenewed }, { accessToken, idToken }) => {
    // eslint-disable-next-line no-console
    console.warn(`[feature/login/oidc-session] Using mocked authorization due to config setting`);

    const signoutRedirect = () => {
        window.location.href = window.location.href.concat('/loggedOut');
        return Promise.resolve();
    };

    const signinSilent = () => {
        onTokenRenewed(
            adaptPublishedInfo({
                access_token: accessToken || 'valid-mocked-oauth-bogus-token',
                // eslint-disable-next-line no-magic-numbers
                expires_in: 60 * 60 * 24 * 365,
                profile: idToken
                    ? jwtDecode(idToken)
                    : {
                        account: config.defaultAccount ? config.defaultAccount : 'mockaccount',
                        azp: 'test-client',
                        email: 'test@example.com',
                        family_name: 'Client',
                        given_name: 'Test',
                        locale: config.login.mockLocale,
                        name: 'Test Client',
                        sub: 'prod-rio-users:mock-user',
                    },
            }),
        );
        return Promise.resolve();
    };
    const clearStaleState = () => {
        console.log('stale state cleared');
        return Promise.resolve();
    };

    return { signinSilent, clearStaleState, signoutRedirect };
};
