import { State } from '../../../types';
import { InitializationState } from '../reducers/types';

export function getSessionExpiredAcknowledged(a: State): boolean {
    return a.app.sessionExpiredAcknowledged;
}

export function getInitializationState(a: State): InitializationState {
    return a.app.initializationState;
}
