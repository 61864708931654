import { Component } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import ApplicationLayoutBodyBottomBar from '@rio-cloud/rio-uikit/lib/es/ApplicationLayoutBodyBottomBar';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import VolkswagenApplicationHeader from '@rio-cloud/rio-uikit/lib/es/VolkswagenApplicationHeader';

import { config } from '../../../config';

import { DEFAULT_LOCALE } from '../../../configuration';
import messagesEN from '../../translations/en-GB.json';
import { InitializationState } from '../reducers/types';

import './App.css';
import { VolkswagenAccountMenuContainer } from './menus/VolkswagenAccountMenuContainer';
import { NoMatch } from './NoMatch';
import { SidebarsLeft, SidebarsRight } from './routing/Routes';
import { RoutesContainer } from './routing/Routes.container';
import { AppProperties, AppState } from './types';
import { DefaultUserMenu as RIOUserMenu } from '@rio-cloud/rio-user-menu-component';

export const SERVICE_INFO_TEST_ID = 'service-info';
export const APP_TEST_ID = 'APP_TEST_ID';
export const APPLICATION_HEADER_VW_TEST_ID = 'APPLICATION_HEADER_VW_TEST_ID';
export const APPLICATION_HEADER_RIO_TEST_ID = 'APPLICATION_HEADER_RIO_TEST_ID';

class ServiceInfo extends Component<{}, {}> {
    render() {
        return (
            <div>
                <div className='line-height-20'>
                    <span className={'rioglyph rioglyph-envelope margin-right-10'} />
                    <a href={'mailto:feedback-avisierung-otc-kassel@rio.cloud?subject=Feedback'} target={'blank'}>
                        <FormattedMessage id={'otc.serviceInfo.feedback.mail'} />
                    </a>
                </div>
                <div className='line-height-20'>
                    <span className={'rioglyph rioglyph-detail-view-info margin-right-10'} />
                    <a href={'https://libs.avisierung.rio.cloud'} target={'blank'}>
                        <FormattedMessage id={'otc.serviceInfo.libraries'} />
                    </a>
                </div>
            </div>
        );
    }
}

const userMenu = config.isVolkswagenBuild ?
    <VolkswagenAccountMenuContainer /> :
    <RIOUserMenu
        environment={import.meta.env.MODE || 'development'}
        preventLogoutRedirect={true}
        disableCookieModal={true}
    />;

export default class App extends Component<AppProperties, AppState> {
    constructor(props: AppProperties) {
        super(props);
        this.state = {
            showPrivacyPolicyDialog: false,
        };
    }

    toggleShowPrivacyPolicyDialog = () => {
        this.setState({ showPrivacyPolicyDialog: !this.state.showPrivacyPolicyDialog });
    };

    componentDidMount() {
        this.props.initialActions()
            .then(this.props.completeInitialization)
            .catch(this.props.setInitializationFailed);
    }

    render() {
        const {
            hideSessionDialog,
            languageData,
            showSessionExpired,
            userLocale,
            initializationState,
        } = this.props;

        if (initializationState !== InitializationState.COMPLETE || !languageData) {
            return (
                <IntlProvider
                    defaultLocale={DEFAULT_LOCALE}
                    locale={DEFAULT_LOCALE}
                    messages={messagesEN}
                    data-testid={APP_TEST_ID}
                >
                    <NotificationsContainer />
                    {initializationState === InitializationState.FAILED && <NoMatch />}
                </IntlProvider>
            );
        }

        const title = (
            <div>
                <span>{'Avisierung - VW OTC Werk Kassel'}</span>
            </div>
        );

        const serviceInfoItem = (
            <ActionBarItem id='serviceInfo' className='myItem'>
                <ActionBarItem.Icon>
                    <span className='icon rioglyph rioglyph-info-sign' data-testid={SERVICE_INFO_TEST_ID} />
                </ActionBarItem.Icon>
                <ActionBarItem.Popover className='myItemPopover' title={title}>
                    <ServiceInfo />
                </ActionBarItem.Popover>
            </ActionBarItem>
        );

        // eslint-disable-next-line jsx-a11y/anchor-has-content
        const homeLink = <NavLink to={'/'} />;

        const internalClassificationLabel = <span
            className={'label label-default line-height-btn padding-top-0 padding-bottom-0'}>
            <FormattedMessage id={'otc.internal'} />
        </span>;
        const privacyPolicyDialogBody = <div>
            <div><FormattedMessage id={'otc.gdpr.tldr'} /></div>
            <div className={'padding-top-20'}>
                <a
                    className={'btn btn-primary'}
                    href={'https://www.vwgroupsupply.com/one-kbp-pub/de/kbp_public/rechtliches_4/privacy_policy/privacy_policy_1.html'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                >
                    <FormattedMessage id={'otc.details'} />
                </a>
            </div>
        </div>;

        const privacyPolicyDialog = <Dialog
            show={this.state.showPrivacyPolicyDialog}
            title={<FormattedMessage id={'otc.gdpr'} />}
            body={privacyPolicyDialogBody}
            footer={''}
            bsSize={Dialog.SIZE_SM}
            onHide={() => this.toggleShowPrivacyPolicyDialog()}
            showCloseButton
        />;

        const bottomBar =
            <ApplicationLayoutBodyBottomBar buttonAlignment={'right'}>
                <span
                    className={'padding-right-10 text-color-darkest cursor-pointer text-decoration-underline'}
                    onClick={() => this.toggleShowPrivacyPolicyDialog()}
                >
                    <FormattedMessage id={'otc.gdpr'} />
                </span>
                {internalClassificationLabel}
            </ApplicationLayoutBodyBottomBar>;

        return (
            <IntlProvider
                defaultLocale={DEFAULT_LOCALE}
                locale={userLocale}
                messages={languageData}
                data-testid={APP_TEST_ID}
            >
                <NotificationsContainer />
                <ApplicationLayout className={'StarterTemplate'}>
                    <ApplicationLayout.Header>
                        {config.isVolkswagenBuild ? <div data-testid={APPLICATION_HEADER_VW_TEST_ID}>
                            <VolkswagenApplicationHeader
                                homeRoute={homeLink}
                                actionBarItems={[serviceInfoItem, userMenu]}
                            />
                        </div> : <ApplicationHeader
                            data-testid={APPLICATION_HEADER_RIO_TEST_ID}
                            homeRoute={homeLink}
                            actionBarItems={[serviceInfoItem, userMenu]}
                        />}
                    </ApplicationLayout.Header>
                    <ApplicationLayout.Sidebar className={'left'}>
                        <SidebarsLeft />
                    </ApplicationLayout.Sidebar>
                    <ApplicationLayout.Sidebar className={'right'}>
                        <SidebarsRight />
                    </ApplicationLayout.Sidebar>
                    <ApplicationLayout.Body
                        className={'responsive'}
                        bottomBar={bottomBar}
                    >
                        {privacyPolicyDialog}
                        <SessionExpiredDialog
                            locale={userLocale}
                            onClose={hideSessionDialog}
                            show={showSessionExpired}
                        />
                        <RoutesContainer />
                    </ApplicationLayout.Body>
                </ApplicationLayout>
            </IntlProvider>
        );
    }
}
