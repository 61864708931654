import { PermissionsActions, UPDATE_USER_PERMISSIONS } from '../../actions/permissions/types';
import { PermissionsState } from './types';

export const initialState: PermissionsState = {
    userPermissions: [],
};

export default function permissionsReducer(
    state: PermissionsState = initialState,
    action: PermissionsActions,
): PermissionsState {
    switch (action.type) {
        case UPDATE_USER_PERMISSIONS:
            return {
                ...state,
                userPermissions: action.payload,
            };
        default:
            return state;
    }
}
