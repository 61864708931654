import {
    AppActions,
    HIDE_SESSION_EXPIRED_DIALOG,
    INITIALIZATION_COMPLETE,
    INITIALIZATION_FAILED,
} from '../actions/types';
import { AppState, InitializationState } from './types';

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    initializationState: InitializationState.INCOMPLETE,
};

export default function reducer(state: AppState = initialState, action: AppActions): AppState {
    switch (action.type) {
        case HIDE_SESSION_EXPIRED_DIALOG:
            return {
                ...state,
                sessionExpiredAcknowledged: true,
            };
        case INITIALIZATION_COMPLETE:
            return {
                ...state,
                initializationState: InitializationState.COMPLETE,
            };
        case INITIALIZATION_FAILED:
            return {
                ...state,
                initializationState: InitializationState.FAILED,
            };
        default:
            return state;
    }
}
