import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'classlist-polyfill';
import 'custom-event-polyfill';
import 'unorm';

if (typeof window['TextEncoder'] !== 'function') {
    const TextEncodingPolyfill = require('text-encoding');
    window['TextEncoder'] = TextEncodingPolyfill.TextEncoder;
    window['TextDecoder'] = TextEncodingPolyfill.TextDecoder;
}
