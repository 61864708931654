import { ConfigState } from './types';
import packageJson from '../package.json';

export enum Tenant {
    RIO='rio',
    VW_QA='vw-qa',
    VW='vw',
}

export const getCurrentTenant = (hostname: String): Tenant => {
    if (hostname.startsWith(Tenant.VW_QA)) {
        return Tenant.VW_QA;
    }
    if (hostname.startsWith(Tenant.VW)) {
        return Tenant.VW;
    }
    return Tenant.RIO;
};

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        USERADMIN_SERVICE: import.meta.env.VITE_USERADMIN_SERVICE,
        USER_SETTINGS_SERVICE: import.meta.env.VITE_USER_SETTINGS_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
    },
    id: import.meta.env.VITE_ID,
    refreshDelay: Number(import.meta.env.VITE_REFRESH_DELAY) || 1,
    autoRefreshTableInterval: Number(import.meta.env.VITE_AUTO_REFRESH_TABLE_INTERVAL_MILLIS) || 30000,
    carrierViewPollInterval: Number(import.meta.env.VITE_CARRIER_VIEW_POLLING_INTERVAL_MILLIS) || 30000,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: import.meta.env.VITE_CLIENT_ID,
        oauthScope: [' '],
        mockAuthorization: getBoolEnvValue('VITE_LOGIN_MOCK_AUTHORIZATION'),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolEnvValue('VITE_LOGIN_PREVENT_REDIRECT'),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    enableMockServer: import.meta.env.DEV,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    isVolkswagenBuild: getBoolEnvValue('VITE_IS_VOLKSWAGEN_BUILD'),
    defaultAccount: import.meta.env.VITE_DEFAULT_ACCOUNT_VALUE,
};

function getBoolEnvValue(envValue: string): boolean {
    return typeof import.meta.env[envValue] !== 'undefined' && import.meta.env[envValue] === 'true';
}
