import Joi from '@hapi/joi';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import { UserPermissionValue, USER_PERMISSION_VALUES, UserPermission } from '../../reducers/permissions/types';
import { neverReachedFor } from '../../../../ts-utils';

export const mapPermissionsResponseToUserPermissions = (permissionsResponse: unknown): Array<UserPermission> => {
    if (proveIsPermissionsResponse(permissionsResponse)) {
        return permissionsResponse.items.map(userPermission => ({
            permissionValue: mapUserPermissionValue(userPermission.permission_value),
            warehouseIds: userPermission.warehouse_ids,
        }));
    }
    throw new Error('Invalid userPermissions response received from backend');
};

export type UserPermissionValueExternal = 'TRANSPORT_ORDER_READ' | 'TRANSPORT_ORDER_CREATE' | 'TRANSPORT_ORDER_EDIT' |
    'TRANSPORT_ORDER_PLAN' | 'TRANSPORT_ORDER_LOAD' | 'TRANSPORT_ORDER_AUDIT';

const mapUserPermissionValue = (permission: UserPermissionValueExternal): UserPermissionValue => {
    switch (permission) {
        case 'TRANSPORT_ORDER_READ':
            return UserPermissionValue.TRANSPORT_ORDER_READ;
        case 'TRANSPORT_ORDER_CREATE':
            return UserPermissionValue.TRANSPORT_ORDER_CREATE;
        case 'TRANSPORT_ORDER_EDIT':
            return UserPermissionValue.TRANSPORT_ORDER_EDIT;
        case 'TRANSPORT_ORDER_PLAN':
            return UserPermissionValue.TRANSPORT_ORDER_PLAN;
        case 'TRANSPORT_ORDER_LOAD':
            return UserPermissionValue.TRANSPORT_ORDER_LOAD;
        case 'TRANSPORT_ORDER_AUDIT':
            return UserPermissionValue.TRANSPORT_ORDER_AUDIT;
        default:
            return neverReachedFor(permission);
    }
};

const proveIsPermissionsResponse = (permissionsResponse: unknown): permissionsResponse is PermissionsResponse => {
    const { error } = PermissionsResponseSchema.validate(permissionsResponse);
    if (error) {
        reportErrorToSentry(error);
    }
    return !error;
};

/* eslint-disable @typescript-eslint/naming-convention */

interface PermissionResponse {
    permission_value: UserPermissionValue;
    warehouse_ids: Array<number>;
}

interface PermissionsResponse {
    items: Array<PermissionResponse>;
}

const PermissionsResponseSchema = Joi.object({
    items: Joi.array().required().items(Joi.object({
        permission_value: Joi.string().valid(...Object.values(USER_PERMISSION_VALUES)),
        warehouse_ids: Joi.array().required().items(Joi.number()),
    })),
});
/* eslint-enable @typescript-eslint/naming-convention */
